<template>
  <div class="costEstimationContainer">
    <div class="title">{{ $fanyi("成本估算") }}</div>
    <div class="footer">
      <div class="leftContainer">
        <div class="purchaseCost" style="margin-bottom: 16px">
          <div class="label">{{ $fanyi("购入总成本") }}</div>
          <div class="priceContainer">
            <div>共 <span>{{ $parent.Count }}</span> 件商品</div>
            <el-divider direction="vertical"></el-divider>
            <div><span>{{ $fun.roundFixed($parent.money) }}</span>
              元（{{ $fun.yenNumSegmentation(Number(Number($parent.money) * Number(exchangeRate)).toFixed(0)) }}円）
            </div>
          </div>
        </div>
        <div class="purchaseCost">
          <div class="label">{{ $fanyi("单件成本") }}</div>
          <div class="priceContainer">
            <div><span>{{
                Number($parent.Count) > 0 ? $fun.roundFixed(Number($parent.money) / Number($parent.Count)) : 0
              }}</span> 元（{{
                Number($parent.Count) > 0 ? $fun.yenNumSegmentation(Number(Number(Number($parent.money) / Number($parent.Count)) * Number(exchangeRate)).toFixed(0)) : 0
              }}円）
            </div>
          </div>
        </div>
      </div>
      <div class="cursorPointer" @click="showDialog">{{ $fanyi("细节确认") }} >></div>
    </div>
    <div class="questionnaireDialogContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :visible.sync="dialogVisible"
          center
          width="1288px">
        <div class="dialogTitle">{{ $fanyi("采购成本计算器") }}</div>
        <div class="dialogTitleTips">* {{
            $fanyi("请注意，计算的结果有与实际费用不同的情况。以及商品实际通关的关税税率由海关决定，可能与当前选择的不同。计算结果仅供参考")
          }}
        </div>
        <div class="questionnaireScrollContainer">
          <div class="tableHeader">
            <div class="No">NO</div>
            <div class="goodName">{{ $fanyi("商品名称") }}</div>
            <div class="specification">商品規格</div>
            <div class="num">{{ $fanyi("购买数") }}</div>
            <div class="price">単価（元）</div>
            <div class="option">{{ $fanyi("附加服务") }}</div>
            <div class="num">重量</div>
            <div class="hsCode">{{ $fanyi("关税") }}</div>
          </div>
          <div class="tableFooter">
            <div v-for="(item,index) in tableData" :key="index" class="tableDateContainer">
              <div class="No">{{ index + 1 }}</div>
              <div class="goodName">
                <div class="flexAndCenter">
                  <el-popover placement="left" trigger="hover">
                    <img :src="item.pic" style="width: 300px;height: 300px">
                    <img slot="reference" :src="item.pic" style="width: 60px;height: 60px;margin-right: 9px">
                  </el-popover>
                  <div class="u-line-3 fontSize14 color333" style="width: 155px;">{{ item.goods_title }}</div>
                </div>
              </div>
              <div class="specification">
                <div>
                  <el-popover placement="top" trigger="hover">
                    <div class="showDetailCon u-line">
                      <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                         :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                        {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                      </p>
                    </div>
                    <div slot="reference" class="showDetailCon u-line">
                      <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                         :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                        {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="num">
                <el-input v-model="item.num" onfocus="this.select()"
                          size="mini" style="width: 100px;" type="number" @blur="getPriceInfo"
                          @input="numTrunc(item)" @keydown.native="newChannelInputLimit"/>
              </div>
              <div class="price">
                <div class="fontSize14 fontWeightBold">{{ item.price }}</div>
              </div>
              <div class="option cursorPointer" @click="getItemOption(index,item)">
                <el-popover placement="bottom" trigger="hover">
                  <div class="u-line" style="width: 300px">
                    <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                         style="text-align: left;width: 300px">
                      <div v-if="optionItem.num>0&&optionItem.checked===true" class="">
                        {{ optionIndex + 1 }}.{{ optionItem.name_translate }}* {{ optionItem.num }}
                      </div>
                    </div>
                  </div>
                  <div slot="reference">
                    <div class="optionHiddenContainer" style="width:150px">
                      <div class="optionIcon">
                        <img class="redEditIcon" src="../../../assets/redEditIcon.png" style="width:12px;height:12px;">
                        <img class="baiEditIcon" src="../../../assets/baiEditIcon.png" style="width:12px;height:12px;">
                      </div>
                      <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex">
                        <div v-if="optionItem.num>0&&optionItem.checked===true" class="u-line"
                             style="text-align: left;width:180px">
                          {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}
                        </div>
                      </div>
                    </div>
                    <div v-show="item.option.length>3" style="text-align:left">........</div>
                  </div>
                </el-popover>
              </div>
              <div class="num">
                <div>
                  <div class="inputContainer">
                    <el-input v-model="item.g" onfocus="this.select()"
                              size="mini" style="width: 100px;"
                              @blur="getPriceInfo"
                              @input="commodityTotal" @keydown.native="newChannelInputLimit"/>
                    <div class="inputText" style="margin-right: 10px">g</div>
                  </div>
                  <div class="text">
                    {{ $fanyi("参考重量") }}<br>
                    {{ $fanyi("成本预估使用") }}
                  </div>
                </div>
              </div>
              <div class="hsCode">
                <div v-if="selectHsCodeStatus==true&&item.hsCode==''" class="fontSize14 colorB4272B cursorPointer"
                     @click="showSelectHsCodeDialog(index)">
                  {{ $fanyi("请选择HS编码") }}
                </div>
                <div v-if="selectHsCodeStatus==false" class="fontSize14 color999">{{
                    $fanyi("该商品获取不到HS编码信息")
                  }}
                </div>
                <div v-if="selectHsCodeStatus==true&&item.hsCode!=''" class="fontSize14 colorB4272B cursorPointer"
                     @click="showSelectHsCodeDialog(index)">
                  <div>{{ item.rate }}</div>
                  <div>{{ item.hsCode }}</div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div class="footerLeftContainer">
              <div class="footerLeftTitle">{{ $fanyi('国际物流选择') }}</div>
              <div class="logisticsContainer">
                <el-select v-model="selectValue" size="mini" style="width: 240px;margin-right: 17px"
                           @change="updateSelectValue">
                  <el-option
                      v-for="item in internationalLogisticsTransportation"
                      :key="item.id"
                      :disabled="item.usable==false"
                      @click.native="triggerChange(item)"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
                <div class="btn" @click="showInternationalFreightReference">{{ $fanyi('国际运费详情') }}</div>
              </div>
              <div class="requiredTitle">
                <span>*</span>
                <div>{{ $fanyi('箱子规格') }}</div>
              </div>
              <div class="boxContainer">
                <div class="inputContainer">
                  <el-input v-model.number="long" :placeholder="$fanyi('长')" size="mini" type="number"
                            @blur="getPriceMessage"/>
                  <div class="inputText">cm</div>
                </div>
                <div class="symbol">x</div>
                <div class="inputContainer">
                  <el-input v-model.number="width" :placeholder="$fanyi('宽')" size="mini" type="number"
                            @blur="getPriceMessage"/>
                  <div class="inputText">cm</div>
                </div>
                <div class="symbol">x</div>
                <div class="inputContainer">
                  <el-input v-model.number="height" :placeholder="$fanyi('高')" size="mini" type="number"
                            @blur="getPriceMessage"/>
                  <div class="inputText">cm</div>
                </div>
              </div>
              <div class="requiredTitle">
                <span>*</span>
                <div>重量</div>
              </div>
              <div class="inputContainer">
                <el-input v-model.number="kgValue" :placeholder="$fanyi('重量')" size="mini" type="number"
                          @blur="getPriceMessage"/>
                <div class="inputText">kg</div>
              </div>
              <div class="footerLeftTips">*注意：{{ $fanyi('所有商品以一箱来计算成本') }}</div>
              <div class="fontSize12 colorB4272B">{{ tips }}</div>
              <div v-if="tips===''">
                <div class="colorB4272B fontSize12" style="margin-bottom: 10px">
                  注意：国際荷物の通関時に発生した関税はお客様のご負担となります。
                </div>
                <div class="colorB4272B fontSize12" style="padding-right: 20px">
                  EMSを除く配送方法は運送会社から税金を立て替えてからお客様に請求されます。(立替手数料が発生する可能性がございます)
                </div>
              </div>
            </div>
            <div class="footerRightContainer">
              <div class="vipTypeContainer">
                <div v-for="(item,index) in vipList" :key="index" class="vipInfoContainer"
                     @click="activeIndex=index;getPriceInfo()">
                  <div v-show="activeIndex==index" class="activeContainer"></div>
                  <img :src="item.img" alt="">
                  <div>
                    <div class="vipName">{{ item.name }}</div>
                    <div class="vipTips">{{ item.tips }}</div>
                  </div>
                </div>
              </div>
              <div v-loading="fullscreenLoading" class="priceInfoContainer">
                <div class="priceLeftInfoContainer">
                  <div class="title">合计</div>
                  <div class="leftPriceInfoItemContainer">
                    <div class="label">数量</div>
                    <div class="value">
                      <span>{{ count }}</span>
                      <div> 件</div>
                    </div>
                  </div>
                  <div class="leftPriceInfoItemContainer">
                    <div class="label">{{ $fanyi('购入总成本') }}</div>
                    <div class="value">
                    <span>{{
                        priceInfo.amount == 0 ? Number(priceInfo.goodsPrice).toFixed(2) : priceInfo.amount
                      }}</span>
                      <div> 元（{{
                          priceInfo.amount == 0 ? $fun.yenNumSegmentation(Number(Number(priceInfo.goodsPrice) * Number(exchangeRate)).toFixed(0)) : $fun.yenNumSegmentation(Number(Number(priceInfo.amount) * Number(exchangeRate)).toFixed(0))
                        }}円）
                      </div>
                    </div>
                  </div>
                  <div class="leftPriceInfoItemContainer">
                    <div class="label">{{ $fanyi('单件成本') }}</div>
                    <div class="value">
                    <span>{{
                        priceInfo.amount_per == 0 ? $fun.roundFixed(Number(Number(priceInfo.goodsPrice).toFixed(2)) / Number(count)) : priceInfo.amount_per
                      }}</span>
                      <div>
                        元（{{
                          priceInfo.amount_per == 0 ? $fun.yenNumSegmentation(Number(Number(Number(priceInfo.goodsPrice) / Number(count)) * Number(exchangeRate)).toFixed(0)) : $fun.yenNumSegmentation(Number(Number(priceInfo.amount_per) * Number(exchangeRate)).toFixed(0))
                        }}円）
                      </div>
                    </div>
                  </div>
                </div>
                <div class="priceRightInfoContainer">
                  <div class="title">{{ $fanyi('明细') }}</div>
                  <div class="rightPriceInfoItemContainer">
                    <div class="label">商品金額</div>
                    <div class="value">
                      <div>{{ Number(priceInfo.goodsPrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                  <div class="rightPriceInfoItemContainer">
                    <div class="label">{{ $fanyi('中国国内运费') }}</div>
                    <div class="value">
                      <div>{{ Number(priceInfo.freightPrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                  <div class="rightPriceInfoItemContainer">
                    <div class="label">手数料（{{
                        `${vipList[activeIndex].value == 'SVIP' || vipList[activeIndex].value == '定额' ? 0 : 5}`
                      }}%）
                    </div>
                    <div class="value">
                      <div>{{ Number(priceInfo.servicePrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                  <div class="rightPriceInfoItemContainer">
                    <div class="label">{{ $fanyi('附加服务费') }}</div>
                    <div class="value">
                      <div>{{ Number(priceInfo.optionPrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                  <div class="rightPriceInfoItemContainer">
                    <div class="label">{{ $fanyi('预估国际运费') }}</div>
                    <div class="value">
                      <div>{{ Number(priceInfo.logisticsPrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                  <div class="rightPriceInfoItemContainer">
                    <div class="label">{{ $fanyi('预估关税') }}</div>
                    <div class="value">
                      <div>{{ Number(priceInfo.tariffPrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                  <div class="rightPriceInfoItemContainer">
                    <div class="label">{{ $fanyi('预计消费税') }}</div>
                    <div class="value">
                      <div>{{ Number(priceInfo.excisePrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                  <div class="rightPriceInfoItemContainer" style="margin-bottom: 0">
                    <div class="label">{{ $fanyi('预计地方消费税') }}</div>
                    <div class="value">
                      <div>{{ Number(priceInfo.localPrice).toFixed(2) }}元</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btnList">
                <el-button type="primary" @click="addCart">{{ $fanyi("加入购物车") }}</el-button>
              </div>
            </div>
          </footer>
        </div>
      </el-dialog>
      <selectHsCode ref="selectHsCodeRef"/>
      <internationalFreightReference ref="internationalFreightReferenceRef"/>
      <orderOption/>
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :visible.sync="noVipDialogVisible"
          append-to-body
          center
          title="注意"
          width="30%">
      <span>
        お客様は定額会員ではない為、定額会員限定ルート「{{ logistics_name }}」のご利用はできません。
        ご希望の場合は、定額会員を申し込んで頂くようにお願い致します。
      </span>
        <span slot="footer" class="dialog-footer">
    <el-button @click="noVipDialogVisible = false">閉じる</el-button>
        <el-button v-if="logistics_name!='Raku-DQ'" type="primary" @click="pageJump(1)">RW船便説明ページへ</el-button>
    <el-button type="primary" @click="pageJump(2)">今すぐ申し込む</el-button>
  </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import selectHsCode from "@/views/order/components/selectHsCode.vue";
import internationalFreightReference from '@/views/order/components/internationalFreightReference.vue'
import orderOption from "@/views/order/components/option.vue";
import throttle from "@/utlis/throttle";

export default {
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  components: {
    selectHsCode,
    internationalFreightReference,
    orderOption
  },
  data() {
    return {
      dialogVisible: false,
      noVipDialogVisible: false,
      tableData: [],
      optionList: [],
      logistics_name: '',
      internationalLogisticsTransportation: [],
      selectValue: '',
      kgValue: null,
      long: '50',
      width: '40',
      height: '30',
      tips: '',
      activeIndex: 0,
      count: 0,
      selectHsCodeStatus: true,
      selectHsCodeDialogVisible: false,
      internationalFreightReferenceDialogVisible: false,
      vipList: [{
        name: '一般会員',
        img: require('../../../assets/userDetail/hy_icon@2x.png'),
        tips: '手续费 5%',
        value: '普通',
      }, {
        name: '定额会員',
        img: require('../../../assets/userDetail/dehy.png'),
        tips: '手续费 0%',
        value: '定额',
      }, {
        name: 'SVIP',
        img: require('../../../assets/userDetail/svip_icon@2x.png'),
        tips: '手续费 0%',
        value: 'SVIP',
      }],
      goodsCostPredictBaseInfo: {
        obj: []
      },
      showGoodsOptionDialogVisible: false,
      showGoodsOptionList: [],
      priceInfo: {
        goodsPrice: 0,
        servicePrice: 0,
        freightPrice: 0,
        optionPrice: 0,
        logisticsPrice: 0,
        tariffPrice: 0,
        excisePrice: 0,
        localPrice: 0,
        amount_per: 0,
        amount: 0,
      },
      orderNum: 0,
      goodsKg: 0,
      fullscreenLoading: false,
      editIndex: 0,
      userInfo: {},
      level_name: '',
      loading: null,
    }
  },
  methods: {
    showDialog() {
      this.count = 0;
      this.priceInfo.goodsPrice = 0;
      this.kgValue = 0;
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (japan_user_token == null) {
        this.$parent.$refs.dialog.visible = true;
        return
      }
      this.tableData = [];
      if (this.$parent.Count == 0) {
        this.$message.error(this.$fanyi('必須項目 購入数量'))
        return
      }
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('用户点击价格预算次数');
      }
      if (this.optionList.length == 0) {
        this.$api.optionList({
          need_desc: true
        }).then((res) => {
          this.optionList = res.data;
        })
      }
      if (JSON.stringify(this.userInfo) == '{}') {
        this.$api.japanGetUserInfo().then((res) => {
          if (res.code != 0) {
            if (res.msg === '現在のログインは無効です，再度ログインしてください。') {
              this.$fun.routerToPage("/");
            }
            return
          }
          this.$store.commit("userData", res.data);
          this.userInfo = res.data;
          this.level_name = res.data.level.currentLevel.level_name;
          if (res.data.level.currentLevel.level_name == '定額会員') {
            this.vipList.splice(0, 1)
          } else if (res.data.level.currentLevel.level_name == 'SVIP') {
            this.vipList.splice(0, 1)
            this.vipList.splice(1, 1)
          }
        });
      }
      if (this.goodsCostPredictBaseInfo.obj.length == 0) {
        let goods_ids = [];
        goods_ids.push(this.$parent.details.goodsId)
        this.$api.goodsCostPredictBase({goods_ids: goods_ids}).then((res) => {
          this.dialogVisible = true;
          this.loading.close();
          if (res.code != 0) return
          if (res.data.tariff.tariff_option.obj == undefined) {
            this.selectHsCodeStatus = false;
          }
          this.goodsCostPredictBaseInfo = res.data.tariff.tariff_option;
          if (!this.$fun.isArray(res.data.goodsFreightRule) || res.data.tariff.tariff_option.obj.length > 0) {
            this.$api.getHsCodeList({goods_ids: goods_ids}).then((result) => {
              this.goodsKg = res.data.goodsFreightRule.singleProductWeight;
              this.kgValue = 0;
              this.tableData.forEach((item, itemIndex) => {
                if (!this.$fun.isArray(res.data.goodsFreightRule)) {
                  let g = (res.data.goodsFreightRule.singleProductWeight * 1000) * item.num
                  item.g = this.$fun.deepClone(g);
                  this.kgValue += this.$fun.deepClone(res.data.goodsFreightRule.singleProductWeight);
                }
                if (res.data.tariff.tariff_option.obj.length > 0) {
                  if (result.data.length == 0) {
                    item.rate = res.data.tariff.tariff_option.obj[0].rate
                    item.hsCode = res.data.tariff.tariff_option.obj[0].hsCode;
                    this.selectHsCodeStatus = true;
                  } else {
                    res.data.tariff.tariff_option.obj.forEach((objItem, objIndex) => {
                      if (objItem.hsCode == result.data[0].hscode_list[0]) {
                        item.rate = objItem.rate;
                        item.hsCode = objItem.hsCode;
                        this.selectHsCodeStatus = true;
                      } else if (objIndex == res.data.tariff.tariff_option.obj.length - 1 && objItem.hsCode != result.data[0].hscode_list[0]) {
                        item.rate = res.data.tariff.tariff_option.obj[0].rate
                        item.hsCode = res.data.tariff.tariff_option.obj[0].hsCode;
                        this.selectHsCodeStatus = true;
                      }
                    })
                  }
                }
                if (itemIndex == this.tableData.length - 1) {
                  this.getPriceInfo()
                }
              })
            })
          }
        });
      } else {
        this.loading.close();
        this.dialogVisible = true;
      }
      if (this.internationalLogisticsTransportation.length == 0) {
        this.$api.internationalLogisticsTransportation().then((res) => {
          res.data.forEach((item) => {
            if (item.name == 'RW船便' || item.name == 'Raku-DQ') {
              item.name += '―定額会員限定ルート'
              if (item.usable == true && this.selectValue == '') {
                this.selectValue = item.id
              }
            }
            if (item.id == 14 && this.selectValue == '') {
              this.selectValue = item.id
            }
          })
          this.internationalLogisticsTransportation = res.data;
        })
      }
      let detail = [];
      let arr = [];
      arr = this.$fun.deepClone(this.$parent.addCartSkuList);
      this.$parent.skuProps.forEach((item, index) => {
        if (index === this.$parent.skuProps.length - 1) {
          for (let i = 0; i < this.$parent.goodsInventoryOption.length; i++) {
            if (this.$parent.goodsInventoryOption[i].num > 0) {
              if (detail.length > 0) {
                if (item.keyC === detail[detail.length - 1].key) {
                  detail[detail.length - 1].value = this.$parent.goodsInventoryOption[i].name
                } else {
                  detail.push({
                    key: item.keyC,
                    value: this.$parent.goodsInventoryOption[i].name,
                  });
                }
              }
              let img = '';
              if (this.$parent.goodsInventoryOption[i].picUrl == undefined) {
                if (this.$parent.goodsInventoryOption[i].newPicUrl !== '') {
                  img = this.$parent.goodsInventoryOption[i].newPicUrl
                } else {
                  img = this.$parent.details.images[0];
                }
              } else {
                img = this.$parent.goodsInventoryOption[i].picUrl;
              }
              arr.push({
                goods_title: this.$parent.details.titleC,
                detail: this.$fun.deepClone(detail),
                num: this.$parent.goodsInventoryOption[i].num,
                price: this.$parent.goodsInventoryOption[i].price,
                pic: img,
                skuId: this.$parent.goodsInventoryOption[i].skuId,
                spec_id: this.$fun.deepClone(this.$parent.goodsInventoryOption[i].specId)
              });
            }
          }
          arr.forEach((item, index) => {
            if (item == undefined) {
              arr.splice(index, 1);
            }
          })
          arr = this.$fun.mergeArrayValue(arr);
          arr.forEach((item) => {
            if (item != undefined && item.num > 0) {
              this.count += Number(item.num)
              this.priceInfo.goodsPrice += Number(item.num) * item.price
              if (typeof item.detail == 'string') {
                item.detail = JSON.parse(item.detail)
              }
              if (this.goodsKg == 0) {
                this.kgValue += 0.0001
              } else {
                this.kgValue += this.goodsKg
              }
              let data = {
                goods_title: this.$parent.details.titleT,
                detail: item.detail,
                num: item.num,
                price: item.price,
                pic: item.pic != undefined ? item.pic : this.$parent.details.images[0],
                hsCode: '',
                rate: '',
                rateVal: 0,
                option: [],
                g: this.goodsKg == 0 ? '1' : this.$fun.deepClone((this.goodsKg * 1000) * item.num),
                sku_id: item.skuId,
                spec_id: item.spec_id
              }
              this.tableData.push(data)
            }
          })
        } else {
          detail.push({
            key: item.keyC,
            value: item.valueC[item.active].name,
          });
        }
      });
    },
    updateSelectValue(e) {
      this.internationalLogisticsTransportation.forEach((item) => {
        if (item.id == e) {
          this.tips = item.tips
          this.getPriceInfo();
        }
      })
    },
    pageJump(val) {
      if (val == 1) {
        window.open('https://blog.rakumart.com/detail?id=73')
      } else {
        window.open('/tollVipApplyFor')
      }
    },
    triggerChange(item) {
      switch (item.name) {
        case 'RW船便―定額会員限定ルート':
          this.logistics_name = 'RW船便';
          break;
        case 'Raku-DQ―定額会員限定ルート':
          this.logistics_name = 'Raku-DQ';
          break;
      }
      if (!item.usable) {
        this.noVipDialogVisible = true;
      }
    },
    getItemOption(index, item) {
      this.editIndex = index;
      this.orderNum = item.num;
      let optionList = this.$fun.deepClone(this.optionList);
      if (item.option.length > 0) {
        item.option.forEach((item1) => {
          optionList.forEach((optionItem) => {
            if (item1.name == optionItem.name) {
              optionItem.num = item1.num;
              optionItem.checked = item1.checked;
            }
          })
        })
      }
      this.showGoodsOptionList = this.$fun.deepClone(optionList);
      this.showGoodsOptionDialogVisible = true
    },
    showSelectHsCodeDialog(index) {
      this.editIndex = index;
      this.$refs.selectHsCodeRef.list = this.$fun.deepClone(this.goodsCostPredictBaseInfo.obj)
      this.selectHsCodeDialogVisible = true;
    },
    showInternationalFreightReference() {
      if (this.long == '' || this.width == '' || this.height == '' || this.kgValue == null) {
        this.$message.error(this.$fanyi('缺少必填项'))
        return
      }
      this.internationalFreightReferenceDialogVisible = true;
      this.$refs.internationalFreightReferenceRef.width = this.$fun.deepClone(this.width)
      this.$refs.internationalFreightReferenceRef.height = this.$fun.deepClone(this.height)
      this.$refs.internationalFreightReferenceRef.long = this.$fun.deepClone(this.long)
      this.$refs.internationalFreightReferenceRef.kg = this.$fun.deepClone(this.kgValue)
      this.$refs.internationalFreightReferenceRef.getInternationalFreightReferenceList();
    },
    //输入框取整
    numTrunc() {
      if (this.goodsKg != 0) {
        this.tableData.forEach((item) => {
          item.g = Number(this.goodsKg * 1000) * Number(item.num);
        })
      }
      this.commodityTotal();
    },
    commodityTotal() {
      let kgValue = 0;
      this.count = 0;
      this.kgValue = 0;
      this.priceInfo.goodsPrice = 0;
      this.tableData.forEach((item) => {
        kgValue += Number(item.g);
        this.count += Number(item.num)
        let valueName = '';
        item.detail.forEach((skuPropsItem, index) => {
          if (index === item.detail.length - 1) {
            valueName += skuPropsItem.value;
          } else {
            valueName += skuPropsItem.value + '㊖㊎';
          }
        });
        this.$parent.details.goodsInfo.goodsInventory.forEach((specificationItem) => {
          if (specificationItem.keyC === valueName) {
            specificationItem.valueC.forEach((valueCItem) => {
              if (valueCItem.specId === item.spec_id && item.num >= Number(valueCItem.startQuantity)) {
                item.price = valueCItem.price;
                this.priceInfo.goodsPrice += Number(item.num) * Number(item.price);
              }
            });
          }
        });
      })
      this.kgValue = kgValue / 1000;
    },
    //输入框校验
    newChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    getPriceMessage() {
      if (this.long != '' && this.width != '' && this.height != '' && this.kgValue != null) {
        this.getPriceInfo();
      }
    },
    getPriceInfo() {
      this.fullscreenLoading = true;
      let goodsArr = [];
      this.tableData.forEach((item) => {
        goodsArr.push({
          goods_id: this.$parent.details.goodsId,
          sku_id: item.sku_id,
          num: item.num,
          price: item.price,
          option: item.option.length == 0 ? '[]' : item.option,
          rateVal: item.rateVal,
          weight: item.g / 1000
        })
      })
      let arr = [{
        width: this.width,
        height: this.height,
        length: this.long,
        weight: this.kgValue
      }];
      this.$api.costPredict({
        boxList: this.long != '' && this.width != '' && this.height != '' && this.kgValue != null ? arr : undefined,
        logistics_id: this.selectValue,
        goods: goodsArr,
        attribute: this.vipList[this.activeIndex].value
      }).then((res) => {
        this.fullscreenLoading = false;
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        this.priceInfo = {
          amount_per: res.data.amount_per,
          amount: res.data.amount,
          goodsPrice: res.data.list.part_goods.amount,
          servicePrice: res.data.list.part_service.amount,
          freightPrice: res.data.list.part_freight.amount,
          optionPrice: res.data.list.part_option.amount,
          logisticsPrice: !this.$fun.isArray(res.data.list.part_logistics.current) ? res.data.list.part_logistics.current.total_money_rmb : 0.00,
          tariffPrice: res.data.list.part_tariff.tax,
          excisePrice: res.data.list.part_tariff.excise_tax,
          localPrice: res.data.list.part_tariff.local_tax,
        }
      });
    },
    addCart() {
      throttle(() => {
        let toCartList = [];
        let traceInfo = {
          isJxhy: this.$route.query.recommendedGoods != undefined && this.$route.query.recommendedGoods == true ? true : false,
          type: this.$route.query.source != undefined ? this.$route.query.source : 'other'
        }
        if (this.$fun.isOfficialWeb()) {
          if (this.$route.query.source == 'index') {
            if (this.$route.query.recommendedGoods == true) {
              __bl.sum('首页爆品商品加入到购物车统计');
            }
            __bl.sum('主页商品加入到购物车统计');
          }
          if (this.$route.query.source != 'index') {
            if (this.$route.query.recommendedGoods == true) {
              __bl.sum('关键词与图搜爆品商品加入到购物车统计');
            }
            switch (this.$route.query.source) {
              case 'link':
                __bl.sum('通过链搜爆品商品加入到购物车统计');
                break;
              case 'img':
                __bl.sum('通过图搜浏览商品加入到购物车统计');
                break;
              case 'keyword':
                __bl.sum('通过词搜浏览商品加入到购物车统计');
                break;
              case 'LandingPage':
                __bl.sum('通过1688跨境选品助手浏览商品加入到购物车统计');
                break;
            }
          }
          if (this.$route.query.source == undefined) {
            __bl.sum('通过其他途径加入到购物车统计');
          }
        }
        this.tableData.forEach((item) => {
          toCartList.push({
            shop_id: this.$parent.details.shopId,
            shop_name: this.$parent.details.shopName,
            goods_id: this.$parent.details.goodsId,
            goods_title: this.$parent.details.titleT,
            detail: JSON.stringify(item.detail),
            from_platform: this.$parent.details.fromPlatform,
            num: item.num,
            price: item.price,
            price_ranges: JSON.stringify(this.$parent.goodsInfo.priceRanges),
            pic: item.pic != undefined ? item.pic : this.$parent.details.images[0],
            trace: JSON.stringify(traceInfo),
            sku_id: item.skuId,
            spec_id: item.spec_id
          })
        })
        this.$api.goodsToCart({to_cart: toCartList}).then((res) => {
          if (res.code != 0) return this.$alert(res.msg);
          this.$parent.Count = 0;
          this.$parent.money = 0;
          this.$parent.goodsInventoryOption.forEach((item) => {
            item.num = 0;
          })
          this.$parent.addCartSkuList.forEach((item) => {
            item.num = 0;
          })
          this.dialogVisible = false;
          this.$message.success(this.$fanyi("购物车添加成功"));
          this.$store.dispatch("goodsToCartNum");
        });
      }, 4000)
    },
  }
}
</script>


<style lang="scss" scoped>
.costEstimationContainer {
  width: 808px;
  background: #F9F9F9;
  border-radius: 6px;
  padding: 18px 26px 18px 24px;
  flex-direction: column;

  .title {
    font-weight: bold;
    color: #000000;
    margin-bottom: 23px;
  }

  .inputContainer {
    width: 100px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    display: flex;
    align-items: center;

    /deep/ .el-input__inner {
      width: 70px;
      height: 25px;
      max-width: 70px;
      border: none;
    }

    /deep/ .is-disabled > .el-input__inner {
      background: #fff;
    }

    /deep/ .el-input {
      width: 70px;
    }

    .inputText {
      text-align: center;
      line-height: 30px;
      color: #333333;
      font-size: 14px;
      border: none;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .leftContainer {
      .purchaseCost {
        display: flex;
        align-items: center;

        .label {
          color: #999999;
          width: 140px;
        }

        .priceContainer {
          display: flex;
          align-items: center;

          div {
            color: #333333;

            span {
              font-weight: bold;
              color: #B4272B;
            }
          }

          .el-divider--vertical {
            height: 10px !important;
          }
        }
      }
    }

    .cursorPointer {
      font-weight: bold;
      color: #B4272B;
    }
  }

  .questionnaireDialogContainer {
    /deep/ .el-dialog {
      border-radius: 10px;
      box-sizing: border-box;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    /deep/ .el-dialog__body {
      padding-top: 0;
    }

    .dialogTitle {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 15px;
    }

    .dialogTitleTips {
      font-size: 12px;
      color: #999999;
      margin-bottom: 20px;
    }

    .questionnaireScrollContainer {
      height: 650px;
      overflow-y: scroll;
    }

    .questionnaireScrollContainer::-webkit-scrollbar {
      width: 0;
    }

    .tableHeader {
      display: flex;
      align-items: center;
      background: #F0F0F0;
      height: 46px;
      border-radius: 10px 10px 0 0;

      div {
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 46px;
      }
    }

    .tableFooter {
      margin-bottom: 32px;

      .tableDateContainer {
        height: 100px;
        border-bottom: 1px solid #DFDFDF;
        display: flex;

        .No, .goodName, .specification, .num, .price, .option, .hsCode {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .optionHiddenContainer {
          height: 55px;
          overflow: hidden;
          position: relative;

          .optionIcon {
            width: 26px;
            height: 16px;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 12px;
              height: 12px
            }

            .redEditIcon {
              display: block;
            }

            .baiEditIcon {
              display: none;
            }
          }
        }

        .optionHiddenContainer:hover {
          border: 1px dashed #B4272B;

          .optionIcon {
            background: #B4272B;

            .redEditIcon {
              display: none;
            }

            .baiEditIcon {
              display: block;
            }
          }
        }
      }
    }

    footer {
      display: flex;

      .footerLeftContainer {
        width: 498px;
        height: 518px;
        background: #FFFFFF;
        border-radius: 10px;
        margin-right: 36px;
        border: 1px solid #DFDFDF;
        padding: 24px 0 0 30px;

        .footerLeftTitle {
          font-weight: bold;
          font-size: 18px;
          color: #333333;
          margin-bottom: 12px;
        }

        .logisticsContainer {
          display: flex;
          align-items: center;

          .btn {
            width: 100px;
            height: 30px;
            background: #B4272B;
            border-radius: 3px;
            cursor: pointer;
            font-size: 14px;
            color: #fff;
            line-height: 30px;
            text-align: center;
          }
        }

        .requiredTitle {
          display: flex;
          font-size: 18px;
          margin: 30px 0 12px;

          span {
            color: #FE0000;
          }

          div {
            font-weight: bold;
            color: #333333;
            margin-left: 4px;
          }
        }

        .boxContainer {
          display: flex;
          align-items: center;

          .symbol {
            margin: 0 10px;
            color: #000000;
            font-size: 19px;
          }
        }
      }

      .footerRightContainer {
        .vipTypeContainer {
          display: flex;
          align-items: center;

          .vipInfoContainer {
            width: 200px;
            height: 66px;
            background: #FFFFFF;
            border-radius: 10px 10px 0 0;
            border: 1px solid #DFDFDF;
            border-bottom: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            .activeContainer {
              position: absolute;
              top: 0;
              left: 0;
              width: 197px;
              height: 5px;
              background: #B4272B;
              border-radius: 10px 10px 0 0;
            }

            img {
              width: 46px;
              height: 32px;
              margin-right: 10px;
            }

            .vipName {
              font-size: 18px;
              color: #333333;
              font-weight: bold;
            }

            .vipTips {
              font-size: 12px;
              color: #999999;
              margin-top: 4px;
            }
          }
        }

        .priceInfoContainer {
          width: 694px;
          height: 380px;
          border: 1px solid #DFDFDF;
          border-radius: 0 0 10px 10px;
          padding: 26px 0 0 42px;
          display: flex;


          .title {
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            margin-bottom: 28px;
          }

          .priceLeftInfoContainer {
            width: 377px;

            .leftPriceInfoItemContainer {
              margin-bottom: 20px;
              display: flex;
              align-items: center;

              .label {
                font-size: 14px;
                color: #333333;
                width: 71px;
                margin-right: 40px;
              }

              .value {
                display: flex;
                align-items: center;

                span {
                  font-size: 14px;
                  font-weight: bold;
                  color: #B4272B;
                }

                div {
                  font-size: 14px;
                  color: #333333;
                  margin-left: 4px;
                }
              }
            }
          }

          .priceRightInfoContainer {
            .rightPriceInfoItemContainer {
              margin-bottom: 20px;
              display: flex;
              align-items: center;

              div {
                font-size: 14px;
                color: #333333;
              }

              .label {
                width: 100px;
                margin-right: 40px;
              }
            }
          }
        }

        .btnList {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;
        }
      }
    }

    .No {
      width: 68px;
    }

    .goodName {
      width: 280px;
    }

    .specification {
      width: 150px;
    }

    .num {
      width: 130px;
    }

    .price {
      width: 110px;
    }

    .option {
      width: 210px;
    }

    .hsCode {
      width: 160px;
    }

    .text {
      font-size: 12px;
      color: #999999;
      margin-top: 6px;
    }

    .footerLeftTips {
      font-size: 12px;
      color: #B4272B;
      margin: 18px 0 10px;
    }
  }
}
</style>