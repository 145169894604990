<template>
  <div ref="echartsRef" style="width: 650px; height: 350px"></div>
</template>

<script>
import echarts from "@/utlis/echarts.min.js";

export default {
  props: {
    option: {
      type: Object,
      require: true,
      default: {}
    },
  },
  data() {
    return {
      chartLine: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.drawPieChart();
    });
  },
  methods: {
    drawPieChart() {
      this.chartLine = echarts.init(this.$el); // 基于准备好的dom，初始化echarts实例
      this.chartLine.setOption(this.option);
    }
  }
}
</script>