<template>
  <div>
    <div class="certificatePageHeader">
      <div class="certificatePageTitle">{{ $fanyi("产品证书") }}</div>
      <el-popover placement="top" trigger="hover">
        <div>{{ $fanyi("该证书为卖家自行提供") }}</div>
        <div slot="reference" class="questionMark" style="margin-top: -17px">?</div>
      </el-popover>
    </div>
    <div class="tableContainer">
      <div class="tableHeaderContainer">
        <div>{{ $fanyi("图片") }}</div>
        <div>{{ $fanyi("证书名") }}</div>
        <div class="flex3">{{ $fanyi("证书编号") }}</div>
        <div>{{ $fanyi("商家提供的证书图片") }}</div>
      </div>
      <div>
        <div v-for="(item,index) in $parent.details.certificateList" :key="index" class="tableItemContainer">
          <div class="flex1">
            <div>
              <el-image
                  :ref="'imgViewRef'+`${index}`"
                  :preview-src-list="item.certificatePhotoList"
                  :src="item.certificatePhotoList[0]"
                  alt=""
                  class="cursorPointer"
                  style="width: 71px;height: 100px;"
              />
            </div>
          </div>
          <div class="flex1">{{ item.certificateName }}</div>
          <div class="flex3">{{ item.certificateCode }}</div>
          <div class="flex1 cursorPointer colorB4272B" @click="lookOverCertificatePhoto('imgViewRef'+`${index}`)">
            {{ $fanyi("查看") }}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  methods: {
    lookOverCertificatePhoto(refName) {
      this.$refs[refName][0].clickHandler();
    }
  }
}
</script>

<style lang="scss" scoped>
.certificatePageHeader {
  display: flex;
  align-items: center;

  .certificatePageTitle {
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    color: #000000;
    border-left: 5px solid #b4272b;
    padding-left: 10px;
    margin: 0 0 20px;
  }

}

.tableContainer {
  margin: 30px 0 52px;

  .tableHeaderContainer {
    background: #F0F0F0;
    border-radius: 10px 10px 0 0;
    height: 40px;
    padding: 0 60px;
    display: flex;

    div {
      line-height: 40px;
      font-size: 14px;
      color: #333333;
      flex: 1;
    }
  }

  .tableItemContainer {
    min-height: 130px;
    display: flex;
    align-items: center;
    padding: 0 60px;
    border-bottom: 1px solid #DFDFDF;

    .flex1 {
      flex: 1;
    }

    .flex1, .flex3 {
      font-size: 14px;
    }
  }
}

</style>