<template>
  <div class="recommendGoodsMain">
    <loginDialog ref="dialog"/>
    <nav v-if="goodsList.length > 0">
      <h4>{{ $fanyi("为你推荐") }}</h4>
    </nav>
    <div v-if="goodsList.length > 0">
      <el-carousel
          ref="shopCarousel"
          :autoplay="autoplay"
          :interval="3000"
          height="305px"
          trigger="click"
          @mouseover.native="autoplay = false"
          @mouseleave.native="autoplay = true"
      >
        <el-carousel-item
            v-for="(item, index) in goodsList"
            v-show="item.status"
            :key="index"
        >
          <div class="recommendGoodsBox">
            <div
                v-for="goodsItem in item"
                :key="goodsItem.goods_id"
                class="recommendGoods"
                @click="toCommodityDetails(goodsItem)"
            >
              <div class="imageBox">
                <el-image :src="goodsItem.imgUrl">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </div>
              <div :title="goodsItem.titleT" class="goodsTitle">
                {{ goodsItem.titleT }}
              </div>
              <div class="priceBox">
                <span class="priceBox">
                  {{
                    goodsItem.goodsPrice
                  }}{{
                    $fanyi("元")
                  }}（{{
                    (goodsItem.goodsPrice
                        * exchangeRate).toFixed(0)
                  }}{{ $fanyi("円") }}）
                </span>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import loginDialog from "@/components/public/loginDialog";

export default {
  data() {
    return {
      goodsList: [],
      autoplay: true,
      translation_name: "",
      chinese_name: "",
    };
  },
  components: {
    loginDialog
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  created() {
    this.getPersonalizedGoods();
  },
  methods: {
    // 获取推荐商品数据
    getPersonalizedGoods() {
      this.$api.similarGoods({
        goods_id: this.$route.query.goods_id
      }).then((res) => {
        if (res.data.data != undefined) {
          this.goodsList = res.data.data;
          this.ProcessingShopData();
        }
      });
    },
    toCommodityDetails(val) {
      if (localStorage.getItem('japan_user_token') == null) {
        this.$refs.dialog.visible = true;
        return
      }
      this.$fun.toCommodityDetails(val.goodsId, val.shopType)
    },
    // 数据处理
    ProcessingShopData() {
      let lack = 6 - (this.goodsList.length % 6); //取得店铺商品数离6个的倍数差多少个商品的数量
      //差多少个就从最前面取多少个填充到最后
      for (let i = 0; i < lack; i++) {
        this.goodsList.push(this.goodsList[i]);
      }
      //  分成六个一组
      this.goodsList = this.$fun.changeArr(this.goodsList, 6);
      this.$forceUpdate();
    },
    // 滑动店铺商品列表
    shopCarouselChange(type) {
      if (type == "left") {
        this.$refs.shopCarousel.prev();
      } else if (type == "right") {
        this.$refs.shopCarousel.next();
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../css/mixin.scss";

.recommendGoodsMain {
  width: $pageWidth;
  margin: 80px auto;

  nav {
    height: 55.88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h4 {
      color: black;
      font-size: 21px;
      font-weight: 600;
    }

    .iconBox {
      img {
        width: 40.88px;
        height: 40.88px;

        &:last-child {
          margin-left: 20px;
          margin-right: 30px;
        }

        opacity: 0.45;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  .recommendGoodsBox {
    display: flex;

    .recommendGoods {
      cursor: pointer;
      width: 210px;
      padding: 15px;
      height: 290px;
      background-color: white;
      border: #e8e8e8 solid 1px;
      border-radius: 6px;
      margin: 14px;
      transition: 0.3s;

      .imageBox {
        width: 100%;
        height: 187.31px;
        margin-bottom: 5px;
        text-align: center;
        background-color: white;

        /deep/ .el-image {
          $imgSize: 181.31px;
          width: $imgSize;
          height: $imgSize;
          transition: 0.3s;
          text-align: center;

          i {
            font-size: $imgSize/4;
            line-height: $imgSize;
          }
        }
      }

      .goodsTitle {
        height: 39px;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
        color: #404040;
        font-family: "Open Sans", Sans-serif;
        font-size: 13px;
        font-weight: 500;
      }

      .priceBox {
        color: #B4272B;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6em;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgb(223, 223, 223);
      }
    }

    .recommendGoods:first-child {
      margin-left: 0;
    }

    .recommendGoods:last-child {
      margin-right: 0;
    }
  }

  .lookMore {
    padding: 22px 34px;
    text-align: right;
    color: #1a289d;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    i {
      font-weight: 800;
      font-size: 14px;
    }
  }
}

/deep/ .el-carousel__arrow--right,
/deep/ .el-carousel__arrow--left {
  display: none;
  width: 100px;
  height: 100px;
  background: #ffffff;
  opacity: 0.6;
  border-radius: 50%;
}

/deep/ .el-carousel__arrow {
  background: rgba(255, 255, 255, 0.5);

  i {
    color: $homePageSubjectColor;
    font-size: 60px;
    font-weight: 700;
  }
}

/deep/ .el-carousel__button {
  display: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
}

/deep/ .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
.el-carousel__button {
  outline: 3px solid $homePageSubjectColor;
  background-color: transparent;
}

.notHaveData {
  $imgSize: 181.31px;
  width: $imgSize;
  height: $imgSize;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: $imgSize/4;
  }
}
</style>
