<template>
  <div class="Container">
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible.sync="$parent.selectHsCodeDialogVisible"
        append-to-body
        center
        custom-class="selectHsCodeDialogContainer"
        width="700px"
        @close="form.keyword=''">
      <div class="selectHsCodeDialogContentContainer">
        <div class="header">
          <div class="flexAndCenter">
            <div>{{ $fanyi('HSCODE编码查询') }}</div>
            <i class="el-icon-close" @click="$parent.selectHsCodeDialogVisible=false"></i>
          </div>
          <div class="borderContainer"></div>
        </div>
        <div class="selectContainer">
          <el-input v-model="form.keyword" size="mini" style="width: 200px;"/>
          <div class="selectBtn" @click="search">{{ $fanyi('搜索') }}</div>
        </div>
        <div class="selectTips">*
          {{ $fanyi('请输入购买商品相关联的品名进行HSCODE匹配，在所有结果中您可以选择最合适的材质与品名') }}
        </div>
        <div>
          <div class="HSCODETableHeader">
            <div>HSCODE</div>
            <div class="flex2">{{ $fanyi('描述') }}</div>
            <div>{{ $fanyi('关税率') }}</div>
            <div>{{ $fanyi('操作') }}</div>
          </div>
          <div v-loading="fullscreenLoading" class="scrollContainer" style="height: 400px;overflow: scroll">
            <div v-for="(item,index) in list" :key="index" class="HSCODETableFooter">
              <div class="flex1">
                <div class="hsCode">{{ item.hsCode }}</div>
              </div>
              <div class="flex2">
                <div class="fontSize14 color333 cursorPointer">{{ item.description }}</div>
              </div>
              <div class="flex1">
                <div>{{ item.rate }}</div>
              </div>
              <div class="flex1">
                <div class="selectBtn" @click="selectItem(item)">{{ $fanyi('确定') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        goods_ids: [this.$parent.$parent.details.goodsId],
        keyword: '',
      },
      fullscreenLoading: false,
      list: this.$fun.deepClone(this.$parent.goodsCostPredictBaseInfo.obj)
    }
  },
  methods: {
    selectItem(item) {
      this.$parent.tableData[this.$parent.editIndex].rate = item.rate;
      this.$parent.tableData[this.$parent.editIndex].rateVal = item.rateVal == undefined ? 0 : item.rateVal;
      this.$parent.tableData[this.$parent.editIndex].hsCode = item.hsCode;
      this.$message.success(this.$fanyi('操作成功'));
      this.form.keyword = '';
      this.$parent.selectHsCodeDialogVisible = false;
      this.$parent.getPriceInfo()
    },
    search() {
      this.list = [];
      this.fullscreenLoading = true;
      this.$api.searchHsCodeOption(this.form).then((res) => {
        this.fullscreenLoading = false;
        if (res.data.tariff_option.obj == undefined) return this.$message.error(this.$fanyi('操作失败'));
        this.list = res.data.tariff_option.obj
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>