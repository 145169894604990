<template>
  <section class="cloud-bed">
    <div v-if="$parent.$parent.wordCloudShow" class="cloud-box">
      <span
          v-for="(item, index) in $parent.$parent.dataList"
          :key="index"
      >
        <router-link
            :style="{color:item.color}"
            :to="{path:'/CommoditySearch',query: {keyword: item.name}}" target="_blank">
                    {{ item.translationName }}
                  </router-link>
      </span>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      timer: 50, // 球体转动速率
      radius: 0, // 词云球体面积大小
      dtr: Math.PI / 180, //鼠标滑过球体转动速度
      active: false, // 默认加载是否开启转动
      lasta: 0, // 上下转动
      lastb: 0.5, // 左右转动
      distr: true,
      tspeed: 0, // 鼠标移动上去时球体转动
      mouseX: 0,
      mouseY: 0,
      tagAttrList: [],
      tagContent: null,
      cloudContent: null,
      sinA: '',
      cosA: '',
      sinB: '',
      cosB: '',
      sinC: '',
      isShow: false,
      cosC: '',
      newTimer: null,
    }
  },
  mounted() {
    this.newTimer = setInterval(() => {
      if (document.querySelector('.cloud-box') != null) {
        setTimeout(() => {
          this.radius = document.querySelector('.cloud-box').offsetWidth / 2
          this.initWordCloud();
          window.clearInterval(this.newTimer);
        }, 1000)
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    initWordCloud() {
      this.cloudContent = document.querySelector('.cloud-box');
      this.tagContent = this.cloudContent.getElementsByTagName('span');
      for (let i = 0; i < this.tagContent.length; i++) {
        let tagObj = {};
        tagObj.offsetWidth = this.tagContent[i].offsetWidth;
        tagObj.offsetHeight = this.tagContent[i].offsetHeight;
        this.tagAttrList.push(tagObj);
      }
      this.sineCosine(0, 0, 0);
      this.positionAll();
      this.cloudContent.onmouseover = () => {
        this.active = true;
      };
      this.cloudContent.onmouseout = () => {
        this.active = false;
      };
      this.cloudContent.onmousemove = (ev) => {
        let oEvent = window.event || ev;
        this.mouseX = oEvent.clientX - (this.cloudContent.offsetLeft + this.cloudContent.offsetWidth / 2);
        this.mouseY = oEvent.clientY - (this.cloudContent.offsetTop + this.cloudContent.offsetHeight / 2);
        this.mouseX /= 5;
        this.mouseY /= 5;
      };
      setInterval(this.update, this.timer);
    },
    positionAll() {
      let phi = 0;
      let theta = 0;
      let max = this.tagAttrList.length;
      let aTmp = [];
      let oFragment = document.createDocumentFragment();
      //随机排序
      for (let i = 0; i < this.tagContent.length; i++) {
        aTmp.push(this.tagContent[i]);
      }
      aTmp.sort(() => {
        return Math.random() < 0.5 ? 1 : -1;
      });
      for (let i = 0; i < aTmp.length; i++) {
        oFragment.appendChild(aTmp[i]);
      }
      this.cloudContent.appendChild(oFragment);
      for (let i = 1; i < max + 1; i++) {
        if (this.distr) {
          phi = Math.acos(-1 + (2 * i - 1) / max);
          theta = Math.sqrt(max * Math.PI) * phi;
        } else {
          phi = Math.random() * (Math.PI);
          theta = Math.random() * (2 * Math.PI);
        }
        //坐标变换
        this.tagAttrList[i - 1].cx = this.radius * Math.cos(theta) * Math.sin(phi);
        this.tagAttrList[i - 1].cy = this.radius * Math.sin(theta) * Math.sin(phi);
        this.tagAttrList[i - 1].cz = this.radius * Math.cos(phi);
        // this.tagContent[i - 1].style.left = this.tagAttrList[i - 1].cx + this.cloudContent.offsetWidth / 2 - this.tagAttrList[i - 1].offsetWidth / 2 + 'px';
        // this.tagContent[i - 1].style.top = this.tagAttrList[i - 1].cy + this.cloudContent.offsetHeight / 2 - this.tagAttrList[i - 1].offsetHeight / 2 + 'px';
      }
    },
    update() {
      let angleBasicA;
      let angleBasicB;

      if (this.active) {
        angleBasicA = (-Math.min(Math.max(-this.mouseY, -200), 200) / this.radius) * this.tspeed;
        angleBasicB = (Math.min(Math.max(-this.mouseX, -200), 200) / this.radius) * this.tspeed;
      } else {
        angleBasicA = this.lasta * 0.98;
        angleBasicB = this.lastb * 0.98;
      }

      //默认转动是后是否需要停下
      // lasta=a;
      // lastb=b;

      // if(Math.abs(a)<=0.01 && Math.abs(b)<=0.01)
      // {
      // return;
      // }
      this.sineCosine(angleBasicA, angleBasicB, 0);
      for (let j = 0; j < this.tagAttrList.length; j++) {
        let rx1 = this.tagAttrList[j].cx;
        let ry1 = this.tagAttrList[j].cy * this.cosA + this.tagAttrList[j].cz * (-this.sinA);
        let rz1 = this.tagAttrList[j].cy * this.sinA + this.tagAttrList[j].cz * this.cosA;

        let rx2 = rx1 * this.cosB + rz1 * this.sinB;
        let ry2 = ry1;
        let rz2 = rx1 * (-this.sinB) + rz1 * this.cosB;

        let rx3 = rx2 * this.cosC + ry2 * (-this.sinC);
        let ry3 = rx2 * this.sinC + ry2 * this.cosC;
        let rz3 = rz2;
        this.tagAttrList[j].cx = rx3;
        this.tagAttrList[j].cy = ry3;
        this.tagAttrList[j].cz = rz3;

        let per = 350 / (350 + rz3);

        this.tagAttrList[j].x = rx3 * per - 2;
        this.tagAttrList[j].y = ry3 * per;
        this.tagAttrList[j].scale = per;
        this.tagAttrList[j].alpha = per;

        this.tagAttrList[j].alpha = (this.tagAttrList[j].alpha - 0.6) * (10 / 6);
      }
      this.doPosition();
      this.depthSort();
    },
    doPosition() {
      let len = this.cloudContent.offsetWidth / 2;
      let height = this.cloudContent.offsetHeight / 2;
      for (let i = 0; i < this.tagAttrList.length; i++) {
        if (this.tagContent[i] != undefined && this.tagContent[i] != null) {
          this.tagContent[i].style.left = this.tagAttrList[i].cx + len - this.tagAttrList[i].offsetWidth / 2 + 'px';
          this.tagContent[i].style.top = this.tagAttrList[i].cy + height - this.tagAttrList[i].offsetHeight / 2 + 'px';
          this.tagContent[i].style.fontSize = Math.ceil(18 * this.tagAttrList[i].scale / 2) + 2 + 'px';
          this.tagContent[i].style.filter = "alpha(opacity=" + 100 * this.tagAttrList[i].alpha + ")";
          this.tagContent[i].style.opacity = this.tagAttrList[i].alpha;
        }
      }
    },
    depthSort() {
      let aTmp = [];
      for (let i = 0; i < this.tagContent.length; i++) {
        aTmp.push(this.tagContent[i]);
      }
      aTmp.sort((item1, item2) => item2.cz - item1.cz);
      for (let i = 0; i < aTmp.length; i++) {
        aTmp[i].style.zIndex = i;
      }
    },
    sineCosine(a, b, c) {
      this.sinA = Math.sin(a * this.dtr);
      this.cosA = Math.cos(a * this.dtr);
      this.sinB = Math.sin(b * this.dtr);
      this.cosB = Math.cos(b * this.dtr);
      this.sinC = Math.sin(c * this.dtr);
      this.cosC = Math.cos(c * this.dtr);
    }
  }
};
</script>


<style scoped>
.cloud-bed {
  width: 300px;
  height: 300px;
  margin: auto;
}

.cloud-box {
  position: relative;
  margin: 20px auto 0px;
  width: 100%;
  height: 100%;
  background: #00000000;
}

.cloud-box span {
  position: absolute;
  padding: 3px 6px;
  top: 0;
  font-weight: bold;
  text-decoration: none;
  left: 0;
  width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 50px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}
</style>
<!--
 * @Description:会动的词云
 * @Author: Vergil
 * @Date: 2021-08-25 14:17:45
 * @LastEditTime: 2021-08-25 17:08:15
 * @LastEditors: Vergil
-->
<!--<template>-->
<!--  <div class="wordCloud" ref="wordCloud">-->
<!--  </div>-->
<!--</template>-->
<!--<script>-->
<!--export default {-->
<!--  name: 'word-cloud',-->
<!--  data() {-->
<!--    return {-->
<!--      color: [-->
<!--        '#a18cd1', '#fad0c4', '#ff8177',-->
<!--        '#fecfef', '#fda085', '#f5576c',-->
<!--        '#fe9a8b', '#30cfd0', '#38f9d7'-->
<!--      ],-->
<!--      wordArr: [],-->
<!--      timer: null,-->
<!--      resetTime: 10,-->
<!--      ContainerSize: ''-->
<!--    };-->
<!--  },-->
<!--  mounted() {-->
<!--    this.init();-->
<!--  },-->
<!--  methods: {-->
<!--    init() {-->
<!--      setTimeout(() => {-->
<!--        this.dealSpan();-->
<!--        this.initWordPos();-->
<!--        this.render();-->
<!--      }, 2000)-->
<!--    },-->
<!--    dealSpan() {-->
<!--      const wordArr = [];-->
<!--      this.$parent.$parent.dataList.forEach((value) => {-->
<!--        console.log(value)-->
<!--        // 根据词云数量生成span数量设置字体颜色和大小-->
<!--        const spanDom = document.createElement('span');-->
<!--        spanDom.style.position = 'relative';-->
<!--        spanDom.style.display = 'inline-block';-->
<!--        spanDom.style.color = this.randomColor();-->
<!--        spanDom.style.fontSize = this.randomNumber(15, 30) + 'px';-->
<!--        spanDom.innerHTML = value.translationName;-->
<!--        spanDom.local = {-->
<!--          position: {-->
<!--            // 位置-->
<!--            x: 0,-->
<!--            y: 0-->
<!--          },-->
<!--          direction: {-->
<!--            // 方向 正数往右 负数往左-->
<!--            x: 1,-->
<!--            y: 1-->
<!--          },-->
<!--          velocity: {-->
<!--            // 每次位移初速度-->
<!--            x: -0.5 + Math.random(),-->
<!--            y: -0.5 + Math.random()-->
<!--          },-->
<!--        };-->
<!--        this.$refs.wordCloud.appendChild(spanDom);-->
<!--        wordArr.push(spanDom);-->
<!--      });-->
<!--      this.wordArr = wordArr;-->
<!--    },-->
<!--    randomColor() {-->
<!--      // 获取随机颜色-->
<!--      var colorIndex = Math.floor(this.color.length * Math.random());-->
<!--      return this.color[colorIndex];-->
<!--    },-->
<!--    randomNumber(lowerInteger, upperInteger) {-->
<!--      // 获得一个包含最小值和最大值之间的随机数。-->
<!--      const choices = upperInteger - lowerInteger + 1;-->
<!--      return Math.floor(Math.random() * choices + lowerInteger);-->
<!--    },-->
<!--    render() {-->
<!--      if (this.resetTime < 100) {-->
<!--        this.resetTime = this.resetTime + 1;-->
<!--        this.timer = requestAnimationFrame(this.render.bind(this));-->
<!--        this.resetTime = 0;-->
<!--      }-->
<!--      this.wordFly();-->
<!--    },-->
<!--    wordFly() {-->
<!--      this.wordArr.forEach((value) => {-->
<!--        // 设置运动方向 大于边界或者小于边界的时候换方向-->
<!--        if (value.local.realPos.minx + value.local.position.x < this.ContainerSize.leftPos.x || value.local.realPos.maxx + value.local.position.x > this.ContainerSize.rightPos.x) value.local.direction.x = -value.local.direction.x;-->
<!--        if (value.local.realPos.miny + value.local.position.y < this.ContainerSize.leftPos.y || value.local.realPos.maxy + value.local.position.y > this.ContainerSize.rightPos.y) value.local.direction.y = -value.local.direction.y;-->
<!--        value.local.position.x += value.local.velocity.x * value.local.direction.x;-->
<!--        value.local.position.y += value.local.velocity.y * value.local.direction.y;-->
<!--        // 给每个词云加动画过渡-->
<!--        value.style.transform = 'translateX(' + value.local.position.x + 'px) translateY(' + value.local.position.y + 'px)';-->
<!--      });-->
<!--    },-->
<!--    initWordPos() {-->
<!--      // 计算每个词的真实位置和容器的位置-->
<!--      this.wordArr.forEach((value) => {-->
<!--        value.local.realPos = {-->
<!--          minx: value.offsetLeft,-->
<!--          maxx: value.offsetLeft + value.offsetWidth,-->
<!--          miny: value.offsetTop,-->
<!--          maxy: value.offsetTop + value.offsetHeight-->
<!--        };-->
<!--      });-->
<!--      this.ContainerSize = this.getContainerSize();-->
<!--    },-->
<!--    getContainerSize() {-->
<!--      // 判断容器大小控制词云位置-->
<!--      const el = this.$refs.wordCloud;-->
<!--      return {-->
<!--        leftPos: {-->
<!--          // 容器左侧的位置和顶部位置-->
<!--          x: el.offsetLeft,-->
<!--          y: el.offsetTop-->
<!--        },-->
<!--        rightPos: {-->
<!--          // 容器右侧的位置和底部位置-->
<!--          x: el.offsetLeft + el.offsetWidth,-->
<!--          y: el.offsetTop + el.offsetHeight-->
<!--        }-->
<!--      };-->
<!--    }-->
<!--  },-->
<!--  destroyed() {-->
<!--    // 组件销毁，关闭定时执行-->
<!--    cancelAnimationFrame(this.timer);-->
<!--  },-->
<!--};-->
<!--</script>-->
<!--<style scoped>-->
<!--.wordCloud {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--}-->
<!--</style>-->
