<template>
  <div class="Container">
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="$parent.internationalFreightReferenceDialogVisible"
        append-to-body
        :show-close="false"
        center
        custom-class="internationalFreightReferenceDialogContainer"
        width="1058px">
      <div class="internationalFreightReferenceContentContainer">
        <div class="header">
          <div class="flexAndCenter">
            <div>{{ $fanyi('国际运费详情') }}</div>
            <i class="el-icon-close" @click="$parent.internationalFreightReferenceDialogVisible=false"></i>
          </div>
          <div class="borderContainer"></div>
        </div>
        <div>
          <div class="goodsTableHeader">
            <div>NO</div>
            <div class="flex2">{{ $fanyi('商品名称') }}</div>
            <div>商品規格</div>
            <div>{{ $fanyi('购买数') }}</div>
            <div>単価（元）</div>
            <div>重量</div>
          </div>
          <div class="scrollContainer" style="height: 100px;overflow: scroll">
            <div v-for="(item,index) in $parent.tableData" :key="index" class="goodsTableFooter">
              <div class="flex1">{{ index + 1 }}</div>
              <div class="flex2">
                <div class="flexAndCenter">
                  <el-popover placement="left" trigger="hover">
                    <img :src="item.pic" style="width: 300px;height: 300px">
                    <img slot="reference" :src="item.pic" style="width: 60px;height: 60px;margin-right: 9px">
                  </el-popover>
                  <div class="u-line-3 fontSize14 color333" style="width: 200px;">{{ item.goods_title }}</div>
                </div>
              </div>
              <div class="flex1">
                <el-popover placement="top" trigger="hover">
                  <div class="showDetailCon u-line">
                    <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                  <div slot="reference" class="showDetailCon u-line">
                    <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                </el-popover>
              </div>
              <div class="flex1">{{ item.num }}</div>
              <div class="flex1">{{ item.price }}</div>
              <div class="flex1">{{ item.g }}g</div>
            </div>
          </div>
        </div>
        <div class="boxMessageContainer">
          <div class="boxSpecificationContainer">
            <div class="boxSpecificationHeaderContainer">
              <div class="boxRequiredTitle">
                <span>*</span>
                <div>{{ $fanyi('箱子规格') }}</div>
              </div>
              <el-select v-model="boxSizeValue" size="mini" style="width: 240px;margin-right: 17px"
                         @change="updateSelectValue">
                <el-option
                    v-for="(item,index) in boxSizeOption"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                    :placeholder="$fanyi('常规箱子规格')">
                </el-option>
              </el-select>
            </div>
            <div class="boxSpecificationFooterContainer">
              <div class="boxSpecificationFooterInputContainer">
                <el-input v-model.number="long" :placeholder="$fanyi('长')" size="mini" type="number"/>
                <div class="boxSpecificationFooterInputText">cm</div>
              </div>
              <div class="boxSpecificationFooterInputSymbol">x</div>
              <div class="boxSpecificationFooterInputContainer">
                <el-input v-model.number="width" :placeholder="$fanyi('宽')" size="mini" type="number"/>
                <div class="boxSpecificationFooterInputText">cm</div>
              </div>
              <div class="boxSpecificationFooterInputSymbol">x</div>
              <div class="boxSpecificationFooterInputContainer">
                <el-input v-model.number="height" :placeholder="$fanyi('高')" size="mini" type="number"/>
                <div class="boxSpecificationFooterInputText">cm</div>
              </div>
            </div>
          </div>
          <div class="boxWeightContainer">
            <div class="boxRequiredTitle" style="margin-bottom: 20px">
              <span>*</span>
              <div>重量</div>
            </div>
            <div class="boxSpecificationFooterInputContainer">
              <el-input v-model.number="kg" size="mini" type="number" @blur="getInternationalFreightReferenceList"/>
              <div class="boxSpecificationFooterInputText">kg</div>
            </div>
          </div>
        </div>
        <div class="logisticsTableHeader">
          <div>{{ $fanyi('发送方式') }}</div>
          <div>{{ $fanyi('合计体积') }}（m³）</div>
          <div>{{ $fanyi('合计实重') }}（kg）</div>
          <div>{{ $fanyi('合计抛重') }}（kg）</div>
          <div>{{ $fanyi('计费数据') }}</div>
          <div>{{ $fanyi('参考国际运费') }}（元）</div>
          <div>操作</div>
        </div>
        <div v-loading="fullscreenLoading" class="scrollContainer" style="height: 200px;overflow: scroll">
          <div v-for="(item,index) in list" :key="index" class="goodsTableFooter" style="height: 50px">
            <div class="flex1">{{ item.logistics_name }}</div>
            <div class="flex1">{{ item.volume }}</div>
            <div class="flex1">{{ item.weight }}</div>
            <div class="flex1">{{ item.volumeWeight }}</div>
            <div class="flex1">{{ item.charged_weight }}</div>
            <div class="flex1">{{ item.total_money_rmb }}</div>
            <div class="flex1">
              <div class="selectBtn" @click="selectLogistics(item)">{{ $fanyi('选择') }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxSizeOption: [
        {
          label: '80*55*35',
          value: '80*55*35'
        },
        {
          label: '60*40*50',
          value: '60*40*50'
        },
        {
          label: '58*51*50',
          value: '58*51*50'
        },
        {
          label: '50*40*30',
          value: '50*40*30'
        },
        {
          label: '50*30*20',
          value: '50*30*20'
        },
        {
          label: '30*20*20',
          value: '30*20*20'
        }
      ],
      boxSizeValue: '',
      option: [],
      list: [],
      width: '',
      height: '',
      long: '',
      kg: '',
      fullscreenLoading: true,
    }
  },
  methods: {
    getInternationalFreightReferenceList() {
      this.fullscreenLoading = true;
      let goodsArr = [];
      this.$parent.tableData.forEach((item) => {
        goodsArr.push({
          goods_id: this.$parent.$parent.details.goodsId,
          sku_id: item.sku_id,
          num: item.num,
          price: item.price,
          option: item.option.length == 0 ? '[]' : item.option,
          rateVal: item.rateVal,
          weight: item.g / 1000
        })
      })
      let arr = [{
        width: this.width,
        height: this.height,
        length: this.long,
        weight: this.kg
      }];
      this.$api.costPredict({
        boxList: arr,
        logistics_id: '',
        goods: goodsArr,
        attribute: this.$parent.level_name == '定額会員' ? '定额' : this.$parent.level_name == 'SVIP' ? 'SVIP' : '普通'
      }).then((res) => {
        this.fullscreenLoading = false;
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        this.list = res.data.list.part_logistics.list
      });
    },
    updateSelectValue(e) {
      let arr = e.split('*');
      this.long = arr[0];
      this.width = arr[1];
      this.height = arr[2];
      this.getInternationalFreightReferenceList();
    },
    selectLogistics(item) {
      this.$parent.width = this.$fun.deepClone(this.width)
      this.$parent.height = this.$fun.deepClone(this.height)
      this.$parent.long = this.$fun.deepClone(this.long)
      this.$parent.kgValue = this.$fun.deepClone(this.kg)
      this.$parent.selectValue = item.logistics_id;
      this.$parent.getPriceInfo();
      this.$parent.internationalFreightReferenceDialogVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>