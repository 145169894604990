import { render, staticRenderFns } from "./internationalFreightReference.vue?vue&type=template&id=a32fdc4c&scoped=true&"
import script from "./internationalFreightReference.vue?vue&type=script&lang=js&"
export * from "./internationalFreightReference.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a32fdc4c",
  null
  
)

export default component.exports