//销售历史趋势与价格历史趋势
export const goodsPriceTrendSeriesOption = {
  tooltip: {
    trigger: 'axis',
    formatter: function (a) {
      var res = ''
      var sum = 0;
      for (var i = 0; i < a.length; i++) {
        //循环两两比较数组中的数字
        for (var j = 0; j < a.length; j++) {
          //if判断，如果数组中的当前一个比后一个大，那么两个交换一下位置
          if (a[j + 1] != undefined) {
            if (Number(a[j].value) > Number(a[j + 1].value)) {
              var tmp = a[j];
              a[j] = a[j + 1];
              a[j + 1] = tmp;
            }
          }
        }
      }
      a = a.reverse();
      a.forEach((item) => {
        sum += Number(item.value)
      })
      a.forEach((item, index) => {
        if (index === 0) {
          res += `${item.axisValue}<br/>`
        }
        res += `${item.marker} ${item.seriesName} : ${item.value}<br/>`
      })
      return res
    }
  },
  legend: {
    data: []
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: []
}